import { useCallback, useEffect, useRef, useState } from "react";
import ReactHowler from "react-howler";
import Image from "next/image";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import clsx from "clsx";
import request from "@/utils/request";

const ProfileVoiceRecord = ({ sound, big = false, size, negative }) => {
  const ref = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [duration, setDuration] = useState(1);
  const [useHover, setUseHover] = useState(sound.read);

  const play = useCallback(() => {
    request.get(`/sound/read/${sound.id}`).then();
    setUseHover(true);
    if (ref.current.howlerState() !== "loaded") {
      ref.current.load();
    } else {
      setPlaying((prev) => !prev);
    }
  }, [sound.id]);

  useEffect(() => setUseHover(sound.read), [sound.read]);

  return (
    <div
      className={clsx("absolute", negative ? "-top-1 -left-1" : "top-0 left-0")}
      role="presentation"
      onClick={play}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div
        className={clsx(
          "absolute inset-0 h-full w-full animate-fade-in cursor-pointer rounded-full bg-grey bg-opacity-20",
          useHover ? "group-hover:flex-center hidden" : "flex-center"
        )}
      >
        <ReactHowler
          ref={ref}
          src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/${sound.path}`}
          preload={false}
          playing={isPlaying}
          onLoad={() => {
            setDuration(Math.ceil(ref.current.duration()));
            setPlaying(true);
          }}
          onLoadError={console.log}
          onEnd={() => setPlaying(false)}
          format={["mp3", "webm", "ogg"]}
          html5
        />
        <Image src={isPlaying ? "/pause.svg" : "/play.svg"} alt="Play" width={big ? 80 : 30} height={big ? 80 : 30} />
      </div>

      {isPlaying && (
        <div className="absolute inset-0 h-full w-full">
          <CountdownCircleTimer
            isPlaying={isPlaying}
            duration={duration}
            colors={[[`#6A35FF`, 1]]}
            size={size}
            strokeWidth={4}
          />
        </div>
      )}
    </div>
  );
};
export default ProfileVoiceRecord;
